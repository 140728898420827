import { Card, Tooltip } from 'antd'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Image from 'components/Image'
import './styles.css'

const CardMovie = ({item}) => {
  const path = useMemo(() => {
    if (!item?.slug) {
      return ''
    }

    let path = '/' + item?.slug

    if ([3, 4].includes(item?.type)) {
      path += '/' + (item?.season || '1') + '/1'
    }
    return path
  }, [ item ])

  const rate = useMemo(() => {
    const rateList = []

    if (item?.rate) {
      if (item?.rate.kinopoisk) {
        rateList.push('kinopoisk - ' + item?.rate.kinopoisk)
      }

      if (item?.rate.imdb) {
        rateList.push('imdb - ' + item?.rate.imdb)
      }
    }

    return rateList.join(', ')
  }, [ item ])

  const country = useMemo(() => _.map(item?.country, 'name').join(', '), [ item ])
  const genre = useMemo(() => _.map(item?.genre, 'name'), [ item ])

  return (
    <Card className="card">
      <div className="card-box">
        <Image item={ item } path={ path }/>
        <div className="card-body">
          <div className="card-body-description">
            <Link to={ path } className="card-title">{ item?.name }</Link>
            <p className="card-desc">
              { rate }
            </p>
            <p className="card-desc">
              { _.compact([ item?.year, country ]).join(', ') }
              { !!genre.length && (
                <Tooltip placement="topLeft" title={ genre.join(', ') }>
                  , { genre[0] }
                </Tooltip>
              ) }
            </p>
            <p className="card-desc">
            </p>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default CardMovie
