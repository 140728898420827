import React, { useLayoutEffect, useMemo, useState } from 'react'
import Layout from 'components/Layout'
import { Breadcrumb, Col, Pagination, Row, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from 'store'
import CardMovie from 'components/CardMovie'
import { Link, useParams } from 'react-router-dom'
import { handleCategoryEntitiesFetch } from 'store/category'
import { HomeOutlined } from '@ant-design/icons'
import './styles.css'

const categoriesHash = {
  films: 'Фильмы',
  series: 'Сериалы',
  cartoons: 'Мультфильмы',
  tvShows: 'Телешоу'
}

const Category = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const [ page, setPage ] = useState(1)

  const entities = useAppSelector(state => state.category.entities)

  const breadcrumbItems = useMemo(() => {
    const items = [ {title: <Link to="/"><HomeOutlined/></Link>} ]

    if (entities.data?.name) {
      items.push({title: <Link to={ `/category/${ params.kind }` }>{ categoriesHash[params.kind] }</Link>})
      items.push({title: entities.data?.name})
    } else {
      items.push({title: categoriesHash[params.kind]})
    }

    return items
  }, [ params, entities ])

  useLayoutEffect(() => {
    if (params.kind) {
      dispatch(handleCategoryEntitiesFetch({slug: params.slug, kind: params.kind, page}))
    }
  }, [ dispatch, params, page ])

  return (
    <Layout loading={ entities.isLoading } title={ entities.data?.name }>
      <Row justify="center" gutter={ [ 20, 20 ] }>
        <Col span={ 24 }>
          <Breadcrumb style={ {margin: '0 30px'} } items={ breadcrumbItems }/>
        </Col>
        <Col span={ 24 }>
          <Typography.Title className="category-title">
            { categoriesHash[params.kind] + (entities.data?.name ? ' ' + entities.data?.name : '') }
          </Typography.Title>
        </Col>
        <Col span={ 24 }>
          { !!entities.data?.items?.length ? (
            <div className="category-container">
              { entities.data?.items?.map(item => (
                <div key={ item?.id } className="category-card">
                  <CardMovie item={ item }/>
                </div>
              )) }
            </div>
          ) : (
            <Typography.Title level={ 3 } className="category-title">
              Эта категория пуста...
            </Typography.Title>
          ) }
        </Col>
        { entities.data?.items?.length < entities.data?.totalCount && (
          <Col span={ 24 }>
            <Row justify="center">
              <Col>
                <Pagination
                  current={ page }
                  total={ entities.data?.totalCount }
                  showSizeChanger={ false }
                  onChange={ p => setPage(p) }
                />
              </Col>
            </Row>
          </Col>
        ) }
      </Row>
    </Layout>
  )
}

export default Category
