import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'

export class PrevSourceButton extends videojs.getComponent('Button') {
  constructor (player: VideoJsPlayer, options: VideoJsPlayerOptions) {
    super(player, options)
    this.controlText('Previous')
    this.setIcon('previous-icon')

    this.skipTime = 10
  }

  buildCSSClass () {
    return `vjs-previous-item-control ${ super.buildCSSClass() }`
  }

  handleClick () {
    if (this.player_.playlist?.previous) {
      this.player_.playlist.previous() 
    } else {
      const currentVideoTime = this.player_.currentTime();

      const liveTracker = this.player_.liveTracker;
      const seekableStart = liveTracker && liveTracker.isLive() && liveTracker.seekableStart();
      let newTime;

      if (seekableStart && (currentVideoTime - this.skipTime <= seekableStart)) {
        newTime = seekableStart;
      } else if (currentVideoTime >= this.skipTime) {
        newTime = currentVideoTime - this.skipTime;
      } else {
        newTime = 0;
      }
      this.player_.currentTime(newTime);
    }
  }
}
