import React, { useLayoutEffect } from 'react'
import Layout from 'components/Layout'
import { Breadcrumb, Col, Row, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from 'store'
import { handleFetchFavorite } from 'store/favorite'
import CardMovie from 'components/CardMovie'
import './styles.css'
import { Link } from 'react-router-dom'
import { HomeOutlined } from '@ant-design/icons'

const Category = () => {
  const dispatch = useAppDispatch()

  const favorites = useAppSelector(state => state.favorite)

  useLayoutEffect(() => {
    dispatch(handleFetchFavorite())
  }, [ dispatch ])

  return (
    <Layout loading={ favorites.isLoading } title={ favorites.data?.name }>
      <Row justify="center" gutter={ [ 20, 20 ] }>
        <Col span={ 24 }>
          <Breadcrumb
            style={{margin: '0 30px'}}
            items={ [
              {title: <Link to="/"><HomeOutlined/></Link>},
              {title: 'Избранные'}
            ] }
          />
        </Col>
        <Col span={ 24 }>
          <Typography.Title className="favorite-title">
            Избранные
          </Typography.Title>
        </Col>
        <Col span={ 24 }>
          { !!favorites.data?.length ? (
            <div className="category-container">
              { favorites.data?.map(item => (
                <div key={item?.id} className='category-card'>
                  <CardMovie item={ item }/>
                </div>
              )) }
            </div>
          ) : (
            <Typography.Title level={ 3 } className="category-title">
              Еще ничего не добавлено...
            </Typography.Title>
          ) }
        </Col>
      </Row>
    </Layout>
  )
}

export default Category
