import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAppSelector } from 'store'
import Login from 'components/Login'

const Scene = () => {
  const auth = useAppSelector(store => store.auth.data)
  return (
    <>
      { !!auth ? (
        <Outlet/>
      ) : (
        <Login/>
      ) }
    </>
  )
}

export default Scene
