import React, { useEffect, useState } from 'react'
import { Input, Button, List, Drawer } from 'antd'
import { useAppDispatch, useAppSelector } from 'store'
import { handleSearchFetch } from 'store/search'
import { SearchOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const Search = () => {
  const [ openModal, setOpenModal ] = useState(false)
  const [ searchValue, setSearchValue ] = useState(undefined)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const searchEntities = useAppSelector(state => state.search.entities)

  useEffect(() => {
    dispatch(handleSearchFetch(searchValue))
  }, [ dispatch, searchValue ])

  return (
    <>
      <Button type="link" icon={ <SearchOutlined/> } onClick={ () => setOpenModal(true) }/>
      <Drawer
        placement="right"
        closable={ false }
        onClose={ () => {
          setOpenModal(false)
          setSearchValue(undefined)
        } }
        open={ openModal }
      >
        <Input
          style={ {marginBottom: '25px'} }
          placeholder="Поиск..."
          allowClear
          value={ searchValue }
          onChange={ e => setSearchValue(e.target.value) }
        />
        <List
          dataSource={ searchEntities }
          renderItem={ (item: any) => (
            <List.Item onClick={ () => {
              setOpenModal(false)
              setSearchValue(undefined)
              let path = '/' + item.slug
              if (item.season) {
                path += '/' + item.season
              }
              navigate(path)
            } }>
              { `${ [ item.name, item.year ].join(', ') } / ${ Object.keys(item.rate).map(rate => `${ rate } - ${ item?.rate[rate] || '?' }`).join(', ') }` }
            </List.Item>
          ) }
        />
      </Drawer>
    </>
  )
}

export default Search
