import React, { useEffect } from 'react'
import { Button, Col, Layout as AntLayout, notification, Row, Spin, Typography } from 'antd'
import Search from 'components/Search'
import SearchDrawer from 'components/SearchDrawer'
import { useWindowSize } from '@uidotdev/usehooks'
import './styles.css'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store'
import { handleLogout } from 'store/auth'
import CategoriesDrawer from 'components/CategoriesDrawer'
import { setNotice } from 'store/notice'
import { FolderOpenOutlined } from '@ant-design/icons'

const {Header, Content, Footer} = AntLayout

const {Text} = Typography

const Layout = ({children, loading, title}) => {
  const dispatch = useAppDispatch()
  const size = useWindowSize()
  const auth = useAppSelector(store => store.auth.data)
  const noticeData = useAppSelector((state) => state.notice)
  const [ notice, contextNotice ] = notification.useNotification({placement: 'bottomRight'})

  useEffect(() => {
    document.title = title ? `${ title } - BGK` : 'BGK'
  }, [ title ])

  useEffect(() => {
    if (noticeData?.message) {
      notice[noticeData?.type || 'open']({message: noticeData?.message})
      dispatch(setNotice({}))
    }
  }, [ noticeData, notice, dispatch ])

  return (
    <AntLayout className="layout">
      <Header className="header">
        <Row justify="space-between" align="middle">
          <Col>
            <Link to="/">
              BGK
            </Link>
          </Col>
          { !!auth && size?.width > 1200 && (
            <Col lg={ 16 }>
              <Row justify="center" gutter={ [ 20, 20 ] }>
                <Col span={ 17 } >
                  <Search/>
                </Col>
                <Col>
                  <CategoriesDrawer/>
                </Col>
                <Col>
                  <Link to="/favorites">
                    <Button type="link" icon={ <FolderOpenOutlined/> }/>
                  </Link>
                </Col>
              </Row>
            </Col>
          ) }
          <Col>
            <Row gutter={ [ 20, 20 ] }>
              { !!auth && size?.width < 1200 && (
                <Col>
                  <Row gutter={ [ 20, 20 ] }>
                    <Col>
                      <SearchDrawer/>
                    </Col>
                    <Col>
                      <CategoriesDrawer/>
                    </Col>
                    <Col>
                      <Link to="/favorites">
                        <Button type="link" icon={ <FolderOpenOutlined/> }/>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              ) }
              <Col>
                { !!auth && (
                  <Button type="primary" onClick={ () => dispatch(handleLogout()) }>Выйти</Button>
                ) }
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row justify="center" align="middle">
          { loading ? <Spin className="spin"/> : (
            <Col span={ 23 }>
              { children }
            </Col>
          ) }
        </Row>
      </Content>
      <Footer>
        <Row justify="center" align="middle">
          <Col>
            <Text>© BGK 2023-{ new Date().getFullYear() }</Text>
          </Col>
        </Row>
      </Footer>
      { contextNotice }
    </AntLayout>
  )
}

export default Layout
