import React, { useCallback } from 'react'
import _ from 'lodash'
import { Input, Dropdown } from 'antd'
import { useAppDispatch, useAppSelector } from 'store'
import { handleSearchFetch } from 'store/search'
import { SearchOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const Search = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const searchEntities = useAppSelector(state => state.search.entities)

  const handleSearch = useCallback((value) => {
    dispatch(handleSearchFetch(value))
  }, [ dispatch ])

  return (
    <Dropdown
      trigger="click"
      placement="bottom"
      autoAdjustOverflow
      menu={ {
        items: _.map(searchEntities, (item) => ({
          label: `${ [ item.name, item.year ].join(', ') } / ${ Object.keys(item.rate).map(rate => `${ rate } - ${ item?.rate[rate] || '?' }`).join(', ') }`,
          key: item.id,
          onClick: () => {
            let path = '/' + item.slug
            if (item.season) {
              path += '/' + item.season + '/1'
            }
            navigate(path)
          }
        }))
      } }
    >
      <div style={ {display: 'inline', paddingBottom: '5px'} }>
        <Input
          placeholder="Поиск..."
          allowClear
          onChange={ e => handleSearch(e.target.value) }
          suffix={ <SearchOutlined/> }
        />
      </div>
    </Dropdown>
  )
}

export default Search
