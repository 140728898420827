import React, { useLayoutEffect } from 'react'
import Layout from 'components/Layout'
import {  Col, Row, Typography } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'store'
import { handleFranchiseFetch } from 'store/franchise'
import CarouselCardMovie from 'components/CarouselCardMovie'
import { Link } from 'react-router-dom'
// import './styles.css'

const Movies = () => {
  const dispatch = useAppDispatch()

  const franchiseEntities = useAppSelector(state => state.franchise.entities)
  const isLoadingFranchiseEntities = useAppSelector(state => state.franchise.isLoading)

  useLayoutEffect(() => {
    dispatch(handleFranchiseFetch())
  }, [ dispatch ])

  return (
    <Layout loading={ isLoadingFranchiseEntities }>
      <Row gutter={ [ 20, 20 ] }>
        <Col span={ 24 }>
          <div className="title-block">
            <Typography.Title className="movies-title">Новинки</Typography.Title>
          </div>
          <CarouselCardMovie entities={ franchiseEntities?.recommends?.items }/>
        </Col>
        <Col span={ 24 }>
          <div className="title-block">
            <Typography.Title className="movies-title">Фильмы</Typography.Title>
            <Link to="/category/films" className="movies-title-link">
              Все фильмы
              <ArrowRightOutlined className="movies-title-icon"/>
            </Link>
          </div>
          <CarouselCardMovie entities={ franchiseEntities?.films?.items }/>
        </Col>
        <Col span={ 24 }>
          <div className="title-block">
            <Typography.Title className="movies-title">Сериалы</Typography.Title>
            <Link to="/category/series" className="movies-title-link">
              Все сериалы
              <ArrowRightOutlined className="movies-title-icon"/>
            </Link>
          </div>
          <CarouselCardMovie entities={ franchiseEntities?.series?.items }/>
        </Col>
        <Col span={ 24 }>
          <div className="title-block">
            <Typography.Title className="movies-title">Мультфильмы</Typography.Title>
            <Link to="/category/cartoons" className="movies-title-link">
              Все мультфильмы
              <ArrowRightOutlined className="movies-title-icon"/>
            </Link>
          </div>
          <CarouselCardMovie entities={ franchiseEntities?.cartoons?.items }/>
        </Col>
        <Col span={ 24 }>
          <div className="title-block">
            <Typography.Title className="movies-title">Телешоу</Typography.Title>
            <Link to="/category/tvShows" className="movies-title-link">
              Все телешоу
              <ArrowRightOutlined className="movies-title-icon"/>
            </Link>
          </div>
          <CarouselCardMovie entities={ franchiseEntities?.tvShows?.items }/>
        </Col>
        <Col span={ 24 }>
          <div className="title-block">
            <Typography.Title className="movies-title">Популярные фильмы</Typography.Title>
          </div>
          <CarouselCardMovie entities={ franchiseEntities?.popFilms?.items }/>
        </Col>
        <Col span={ 24 }>
          <div className="title-block">
            <Typography.Title className="movies-title">Популярные сериалы и телешоу</Typography.Title>
          </div>
          <CarouselCardMovie entities={ franchiseEntities?.popSerials?.items }/>
        </Col>
      </Row>
    </Layout>
  )
}

export default Movies
