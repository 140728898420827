import { Button, Image as AntdImage } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { FolderOutlined, FolderFilled } from '@ant-design/icons'
import { handleAddFavorite, handleDeleteFavorite } from '../../store/favorite'
import { useAppDispatch, useAppSelector } from 'store'
import './styles.css'
import { Link } from 'react-router-dom'

const Image = ({item, path = ''}) => {
  const dispatch = useAppDispatch()
  const favorites = useAppSelector(state => state.favorite.data)

  const favoriteItem = useMemo(() => favorites?.find(i => i.id === item?.id), [ item, favorites ])

  const handleAddToFavorite = useCallback(() => {
    dispatch(handleAddFavorite(item))
  }, [ item, dispatch ])

  const handleRemoveFavorite = useCallback(() => {
    dispatch(handleDeleteFavorite(favoriteItem))
  }, [ favoriteItem, dispatch ])

  const image = useMemo(() => (
    <AntdImage
      alt={ item?.name }
      src={ item?.poster?.hasOwnProperty('260x364') ? item?.poster['260x364'] : '' }
      preview={ false }
    />
  ), [item])

  return (
    <div className="image-block">
      { path ? <Link to={ path } children={ image }/> : image }
      <div className="button-favorite">
        { !!favoriteItem ? (
          <Button icon={ <FolderFilled/> } onClick={ handleRemoveFavorite }/>
        ) : (
          <Button icon={ <FolderOutlined/> } onClick={ handleAddToFavorite }/>
        ) }
      </div>
    </div>
  )
}

export default Image
