import Movies from './Movies/route'
import Film from './Film/route'
import Category from './Category/route'
import Favorite from './Favorite/route'
import Error from './Error'
import Scene from './index'

const route = {
  path: '/',
  element: <Scene/>,
  children: [ Movies, Category, Film, Favorite ],
  errorElement: <Error/>
}
export default route
