import React from 'react'
import {  Tabs } from 'antd'
import { useAppSelector } from 'store'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import './styles.css'

const SeriesTabs = () => {
  const params = useParams()
  const navigate = useNavigate()

  const filmData = useAppSelector(state => state.film.data)

  return filmData.movie?.playlist?.seasons && [ 3, 4 ].includes(filmData.view.type) ? (
    <Tabs
      defaultActiveKey={ params?.season }
      items={ _.chain(filmData.movie?.playlist?.seasons).sortBy('season').map((season) => ({
        label: `Сезон ${ season.season }`,
        key: _.toString(season.season),
        children: (
          <div className="series-carousel">
            { _.map(season.episodes, episode => (
              <div
                key={ episode.id }
                onClick={ () => {
                  navigate(`/${ params.slug }/${ season.season }/${ episode.episode }`)
                } }
                className="series-carousel-item"
              >
                <div
                  className={ `episode ${ episode.episode === params?.episode && _.toNumber(params?.season) === season.season ? 'episode-active' : '' }` }
                >
                  <img width="100%" src={ episode?.poster } alt=""/>
                  { episode.title }
                </div>
              </div>
            )) }
          </div>
        )
      })).value() }
    />
  ) : null
}

export default SeriesTabs
