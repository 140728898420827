import React, { useLayoutEffect, useState } from 'react'
import { Button, Drawer, Tabs, Row, Col } from 'antd'
import { useAppDispatch, useAppSelector } from 'store'
import { handleCategoryFetch } from 'store/category'
import { UnorderedListOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const categoriesHash = {
  films: 'Фильмы',
  series: 'Сериалы',
  cartoons: 'Мультфильмы'
}

const CategoriesDrawer = () => {
  const [ openModal, setOpenModal ] = useState(false)
  const dispatch = useAppDispatch()

  const categories = useAppSelector(state => state.category.categories)

  useLayoutEffect(() => {
    dispatch(handleCategoryFetch())
  }, [ dispatch ])

  return (
    <>
      <Button type="link" icon={ <UnorderedListOutlined/> } onClick={ () => setOpenModal(true) }/>
      <Drawer
        title="Категории"
        placement="right"
        onClose={ () => {
          setOpenModal(false)
        } }
        open={ openModal }
      >
        <Tabs
          tabPosition={ 'left' }
          items={ Object.keys(categories || {}).map(label => ({
            label: categoriesHash[label],
            key: label,
            children: (
              <Row gutter={ [ 10, 10 ] }>
                { categories[label].map(cat => (
                  <Col key={ cat?.id } sm={ 12 } xs={ 24 }>
                    <Link
                      to={ `/category/${ label }/${ cat?.slug }` }
                      onClick={ () => setOpenModal(false) }
                    >
                      { cat?.name }
                    </Link>
                  </Col>
                )) }
              </Row>
            )
          })) }
        />
      </Drawer>
    </>
  )
}

export default CategoriesDrawer
