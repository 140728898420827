import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import search from './search'
import franchise from './franchise'
import film from './film'
import auth, { handleLogout } from './auth'
import notice, { setNotice } from './notice'
import category from './category'
import favorite from './favorite'

const store = configureStore({
  reducer: {
    search,
    franchise,
    film,
    auth,
    notice,
    category,
    favorite
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(store => next => action => {
    if (!!action?.error) {
      store.dispatch(setNotice({type: 'error', message: action.error.message}))

      if (action.error.message === 'Missing or insufficient permissions.') {
        store.dispatch(handleLogout())
      }
    }
    return next(action)
  })
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
