import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'

interface SearchState {
  entities: any;
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: any
}

const initialState = {
  entities: [],
  status: 'idle',
  error: null
} as SearchState

export const handleSearchFetch = createAsyncThunk(
  'search/fetch',
  async (search: string) => {

    const trimSearch = _.trim(search)

    if (!trimSearch) {
      return null
    }

    try {
      const response = await axios.get('https://api.zombie-film.live/v2/franchise/search/', {
        params: {
          search,
          page: 1.,
          'per-page': 42,
          ref: 'localhost',
          _format: 'json'
        }
      })
      return response.data?.items
    } catch (e) {
      return null
    }
  }
)

const searchSlice = createSlice<SearchState, any>({
  name: 'search',
  initialState,
  reducers: null,
  extraReducers: (builder) => {
    builder.addCase(handleSearchFetch.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(handleSearchFetch.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.entities = action.payload ? action.payload : state.entities
    })
    builder.addCase(handleSearchFetch.rejected, (state, action: any) => {
      state.status = 'failed'
      state.error = action.error
      state.entities = []
    })
  }
})

export default searchSlice.reducer
