import { createSlice } from '@reduxjs/toolkit'

interface NoticeState {
  message: string;
  type: string
}

const initialState = {
  message: null,
  type: null
} as NoticeState

const noticeSlice = createSlice<NoticeState, any>({
  name: 'notice',
  initialState,
  reducers: {
    setNotice: (state, action) => action.payload
  }
})

export const {setNotice} = noticeSlice.actions

export default noticeSlice.reducer
