import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

interface FranchiseState {
  entities: {
    recommends: any,
    popFilms: any,
    popSerials: any,
    films: any,
    series: any,
    cartoons: any
    tvShows: any
  };
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: any;
  isLoading: boolean;
}

const initialState = {
  entities: {},
  status: 'idle',
  error: null,
  isLoading: false
} as FranchiseState

const apiUrl = 'https://api.zombie-film.live/v2/franchise/'

export const handleFranchiseFetch = createAsyncThunk(
  'franchise/fetch',
  async () => {
    const baseParams: any = {
      page: 1,
      'per-page': 42,
      _format: 'json'
    }

    const promises = []

    promises.push(axios.get(apiUrl, {
      params: {
        slug: 'featured',
        findBy: 'custom',
        ...baseParams
      }
    }))

    promises.push(axios.get(apiUrl, {
      params: {
        slug: 'homeOne',
        findBy: 'custom',
        ...baseParams
      }
    }))

    promises.push(axios.get(apiUrl, {
      params: {
        slug: 'homeTwo',
        findBy: 'custom',
        ...baseParams
      }
    }))

    baseParams.kinopoisk = 6
    baseParams.imdb = 6
    baseParams.findBy = 'type'

    promises.push(axios.get(apiUrl, {
      params: {
        type: [ 1 ],
        ...baseParams
      }
    }))

    promises.push(axios.get(apiUrl, {
      params: {
        type: [ 6 ],
        ...baseParams
      }
    }))

    promises.push(axios.get(apiUrl, {
      params: {
        type: [ 2 ],
        ...baseParams
      }
    }))

    promises.push(axios.get(apiUrl, {
      params: {
        type: [ 4 ],
        ...baseParams
      }
    }))

    const [ recommends, popSerials, popFilms, films, series, cartoons, tvShows ] = await Promise.all(promises)

    return {
      recommends: recommends.data,
      popFilms: popFilms.data,
      popSerials: popSerials.data,
      films: films.data,
      series: series.data,
      cartoons: cartoons.data,
      tvShows: tvShows.data
    } as any
  }
)

const franchiseSlice = createSlice<FranchiseState, any>({
  name: 'franchise',
  initialState,
  reducers: null,
  extraReducers: (builder) => {
    builder.addCase(handleFranchiseFetch.pending, (state) => {
      state.status = 'pending'
      state.isLoading = true
    })
    builder.addCase(handleFranchiseFetch.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.entities = action.payload as any
      state.isLoading = false
    })
    builder.addCase(handleFranchiseFetch.rejected, (state, action: any) => {
      state.status = 'failed'
      state.error = action.error
      state.entities = {} as any
      state.isLoading = false
    })
  }
})

export default franchiseSlice.reducer
