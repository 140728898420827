import React, { useCallback, useEffect, useState } from 'react'
import qualitySelectorHls from './plugins/QualityLevelsHLS/plugin'
import videojs, { VideoJsPlayer } from 'video.js'
import _ from 'lodash'
import './styles.css'
import 'video.js/dist/video-js.css'
import playlist from 'videojs-playlist'
import airplay from '@silvermine/videojs-airplay'
import "videojs-hotkeys";
import 'videojs-errors';
import { NextSourceButton } from './plugins/NextSourceButton'
import { PrevSourceButton } from './plugins/PrevSourceButton'

videojs.extend = (CompVJS: any, CompPlugin: any) => {
  return Object.assign(CompVJS, CompPlugin)
}

const videojsPlugins = videojs.getPlugins()

!videojsPlugins.hasOwnProperty('qualitySelectorHls') && videojs.registerPlugin('qualitySelectorHls', qualitySelectorHls)
!videojsPlugins.hasOwnProperty('playlist') && videojs.registerPlugin('playlist', playlist)

videojs.registerComponent('NextSourceButton', NextSourceButton)
videojs.registerComponent('PrevSourceButton', PrevSourceButton)
videojs.registerComponent('Separator', videojs.getComponent('spacer'))

airplay(videojs)


const Video = ({src, poster, audioNames, sources, handleChangeEpisode}) => {
  const [ player, setPlayer ] = useState<VideoJsPlayer>(null)

  useEffect(() => {
    const currentIndex = _.findIndex(sources, item => _.find(item.sources, {src}))

    if (player?.playlist?.currentItem && player?.playlist?.currentItem() !== currentIndex) {
      player.playlist.currentItem(currentIndex)
    }
  }, [ src, player, sources ])

  useEffect(() => {
    if (player) {
      const audioTrackList = player.audioTracks()

      audioTrackList.on('addtrack', e => {
        const index = _.toNumber(e.track.label?.slice(3))

        if (audioNames?.names[index] !== 'delete') {
          try {
            e.track.label = audioNames?.names[index]
          } catch (e) {
          }
        } else {
          audioTrackList.removeTrack(e.track)
        }
      })

      if (sources?.length) {

        const currentIndex = _.find(sources, item => _.find(item.sources, {src}))

        player.playlist(sources, currentIndex)

        player.on('playlistitem', () => {
          handleChangeEpisode(sources[player.playlist.currentItem()])
        })

        player.on('ended', () => {
          player.playlist.next()
        })
      }

      player.airPlay()
    }
  }, [ player, audioNames, sources, src, handleChangeEpisode ])

  const handleRef = useCallback(ref => {
    if (ref && !player && src) {
      videojs(ref, {
        preload: 'auto',
        controls: true,
        muted: false,
        bigPlayButton: true,
        suppressNotSupportedError: false,
        nativeControlsForTouch: false,
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        controlBar: {
          children: [ 'playToggle', 'PrevSourceButton', 'NextSourceButton', 'volumePanel', 'progressControl', 'remainingTimeDisplay', 'spacer', 'captionsButton', 'audioTrackButton', 'pictureInPictureToggle', 'airPlayButton', 'fullscreenToggle' ],
          volumePanel: true,
          playToggle: true,
          captionsButton: false,
          chaptersButton: false,
          subtitlesButton: false,
          remainingTimeDisplay: true,
          progressControl: true,
          fullscreenToggle: true,
          playbackRateMenuButton: false,
          pictureInPictureToggle: true,
          currentTimeDisplay: false,
          timeDivider: false,
          durationDisplay: false,
          liveDisplay: false,
          seekToLive: false,
          customControlSpacer: false,
          descriptionsButton: false,
          subsCapsButton: false,
          audioTrackButton: true
        },
        plugins: {
          qualitySelectorHls: {},
          reloadSourceOnError: {},
          airPlay: {},
          hotkeys: {
            volumeStep: 0.1,
            seekStep: 5,
            enableModifiersForNumbers: false
          },
        },
        poster: poster,
        sources: [ {
          src: src,
          type: 'application/x-mpegURL'
        } ]
      }, function () {
        setPlayer(this)
      })
    }
  }, [ player, poster, src ])

  return src ? (
    <video
      className="vjs-custom video-js vjs-big-play-centered vjs-fluid"
      controls
      playsInline
      ref={ handleRef }
    >
      <source src={ src } type="application/x-mpegURL"/>
    </video>
  ) : null
}

export default Video
