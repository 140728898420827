import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { initializeApp } from 'firebase/app'
import antdRu from 'antd/es/locale/ru_RU'
import antTheme from './ant-theme.json'
import store from './store'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'

const firebaseConfig = {
  apiKey: 'AIzaSyD813NjtHsRH8XGc_2NDF7P8h7nklp8vAI',
  authDomain: 'boogeek-81bb3.firebaseapp.com',
  projectId: 'boogeek-81bb3',
  storageBucket: 'boogeek-81bb3.appspot.com',
  messagingSenderId: '531776231875',
  appId: '1:531776231875:web:c06080fddb09eee3460576',
  measurementId: 'G-PPWER3M575'
}

initializeApp(firebaseConfig, 'BGK')

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <ConfigProvider locale={ antdRu } theme={ antTheme }>
      <Provider store={ store }>
        <App/>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
