import React from 'react'
import Layout from 'components/Layout'
import { Typography } from 'antd'
import { useRouteError } from 'react-router-dom'
import './styles.css'

const {Text} = Typography

const NotFound = () => {
  let error = useRouteError()
  return (
    <Layout>
      <div className="not-found">
        <Text>Что то пошло не так...</Text>
        <Text>{ error }</Text>
      </div>
    </Layout>
  )
}

export default NotFound
