import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { collection, doc, addDoc, getDocs, deleteDoc, getFirestore, where, query } from 'firebase/firestore'
import { setNotice } from '../notice'

const getCollection = (uid) => {
  const app = getApp('BGK')
  getAuth(app)
  const db = getFirestore(app)
  return collection(db, 'favorites', uid, 'list')
}

interface favoriteState {
  data: any;
  add: {
    status: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: any;
    isLoading: boolean;
  };
  delete: {
    status: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: any;
    isLoading: boolean;
  };
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: any;
  isLoading: boolean;
}

const initialState = {
  data: [],
  add: {
    status: 'idle',
    error: null,
    isLoading: false
  },
  delete: {
    status: 'idle',
    error: null,
    isLoading: false
  },
  status: 'idle',
  error: null,
  isLoading: false
} as favoriteState

export const handleFetchFavorite = createAsyncThunk(
  'favorite/fetch',
  async (data, thunkAPI): any => {
    const user = thunkAPI.getState().auth?.data
    if (user) {
      const docSnap = await getDocs(query(getCollection(user.uid), where('uid', '==', user.uid)))
      return docSnap.docs.map(doc => ({
        ...doc.data(),
        fbId: doc.id
      }))
    }
  })

export const handleAddFavorite = createAsyncThunk(
  'favorite/add',
  async (data, thunkAPI): any => {
    const user = thunkAPI.getState().auth?.data
    if (user) {
      const existsDoc = await getDocs(query(getCollection(user.uid), where('uid', '==', user.uid), where('id', '==', data.id)))

      if (!existsDoc.size) {
        await addDoc(getCollection(user.uid), {...data, uid: user.uid})
        thunkAPI.dispatch(handleFetchFavorite())
        thunkAPI.dispatch(setNotice({type: 'info', message: `${ data.name } - добавлен в избранное`}))
      }
    }
  })

export const handleDeleteFavorite = createAsyncThunk(
  'favorite/delete',
  async (data, thunkAPI): any => {
    const user = thunkAPI.getState().auth?.data
    if (user) {
      await deleteDoc(doc(getCollection(user.uid), data.fbId))
      thunkAPI.dispatch(handleFetchFavorite())
      thunkAPI.dispatch(setNotice({type: 'info', message: `${ data.name } - удален из избранных`}))
    }
  })

const favoriteSlice = createSlice<favoriteState, any>({
  name: 'favorite',
  initialState,
  reducers: null,
  extraReducers: (builder) => {
    builder.addCase(handleFetchFavorite.pending, (state) => {
      state.status = 'pending'
      state.isLoading = true
    })
    builder.addCase(handleFetchFavorite.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(handleFetchFavorite.rejected, (state, action: any) => {
      state.status = 'failed'
      state.isLoading = false
      state.error = action.error
    })
    builder.addCase(handleAddFavorite.pending, (state) => {
      state.add.status = 'pending'
      state.add.isLoading = true
    })
    builder.addCase(handleAddFavorite.fulfilled, (state) => {
      state.add.status = 'succeeded'
      state.add.isLoading = false
    })
    builder.addCase(handleAddFavorite.rejected, (state, action: any) => {
      state.add.status = 'failed'
      state.add.isLoading = false
      state.add.error = action.error
    })
    builder.addCase(handleDeleteFavorite.pending, (state) => {
      state.delete.status = 'pending'
      state.delete.isLoading = true
    })
    builder.addCase(handleDeleteFavorite.fulfilled, (state) => {
      state.delete.status = 'succeeded'
      state.delete.isLoading = false
    })
    builder.addCase(handleDeleteFavorite.rejected, (state, action: any) => {
      state.delete.status = 'failed'
      state.delete.isLoading = false
      state.delete.error = action.error
    })
  }
})

export default favoriteSlice.reducer
