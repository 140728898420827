import React, { useEffect } from 'react'
import Routes from 'routes'
import { useAppDispatch } from 'store'
import './App.css'
import { handleFetchFavorite } from './store/favorite'

function App () {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(handleFetchFavorite())
  }, [ dispatch ])

  return (
    <Routes/>
  )
}

export default App
