import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const kindHash = {
  films: 1,
  series: 3,
  tvShows: 4,
  cartoons: 2
}

interface CategoryState {
  categories: any;
  entities: {
    data: any;
    status: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: any;
    isLoading: boolean;
  };
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: any;
  isLoading: boolean;
}

const initialState = {
  categories: {},
  entities: {
    data: {},
    status: 'idle',
    error: null,
    isLoading: false
  },
  status: 'idle',
  error: null,
  isLoading: false
} as CategoryState

export const handleCategoryFetch = createAsyncThunk(
  'category/fetch',
  async () => {
    const response = await axios.get('https://api.zombie-film.live/v2/genre/group/', {
      params: {
        by: 'category',
        ref: 'localhost',
        _format: 'json'
      }
    })
    return response.data
  }
)

export const handleCategoryEntitiesFetch = createAsyncThunk(
  'category/entities',
  async ({slug, page, kind}) => {
    const response = await axios.get('https://api.zombie-film.live/v2/franchise/', {
      params: {
        type: [ kindHash[kind] ],
        slug: slug,
        sortBy: 'release',
        findBy: slug ? 'genre' : 'type',
        asPath: `/${ kind }${ slug ? '/' + slug : '' }`,
        page: page,
        'per-page': 42,
        ref: 'localhost',
        _format: 'json'
      }
    })
    return response.data
  }
)

const categorySlice = createSlice<CategoryState, any>({
  name: 'category',
  initialState,
  reducers: null,
  extraReducers: (builder) => {
    builder.addCase(handleCategoryFetch.pending, (state) => {
      state.status = 'pending'
      state.isLoading = true
    })
    builder.addCase(handleCategoryFetch.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.categories = action.payload
      state.isLoading = false
    })
    builder.addCase(handleCategoryFetch.rejected, (state, action: any) => {
      state.status = 'failed'
      state.error = action.error
      state.categories = {}
      state.isLoading = false
    })
    builder.addCase(handleCategoryEntitiesFetch.pending, (state) => {
      state.entities.status = 'pending'
      state.entities.isLoading = true
    })
    builder.addCase(handleCategoryEntitiesFetch.fulfilled, (state, action) => {
      state.entities.status = 'succeeded'
      state.entities.data = action.payload
      state.entities.isLoading = false
    })
    builder.addCase(handleCategoryEntitiesFetch.rejected, (state, action: any) => {
      state.entities.status = 'failed'
      state.entities.error = action.error
      state.entities.data = {}
      state.entities.isLoading = false
    })
  }
})

export default categorySlice.reducer
