import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'

export class NextSourceButton extends videojs.getComponent('Button') {
  constructor (player: VideoJsPlayer, options: VideoJsPlayerOptions) {
    super(player, options)
    this.controlText('Next')
    this.setIcon('next-icon')
    this.skipTime = 10
  }

  buildCSSClass () {
    return `vjs-next-item-control ${ super.buildCSSClass() }`
  }

  handleClick () {
    if (this.player_.playlist?.next) {
      this.player_.playlist.next()
    } else {
      if (isNaN(this.player_.duration())) {
        return;
      }

      const currentVideoTime = this.player_.currentTime();
      const liveTracker = this.player_.liveTracker;
      const duration = (liveTracker && liveTracker.isLive()) ? liveTracker.seekableEnd() : this.player_.duration();
      let newTime;

      if (currentVideoTime + this.skipTime <= duration) {
        newTime = currentVideoTime + this.skipTime;
      } else {
        newTime = duration;
      }

      this.player_.currentTime(newTime);
    }
  }
}
