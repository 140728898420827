import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAuth, setPersistence, signInWithEmailAndPassword, browserLocalPersistence, signOut } from 'firebase/auth'
import { getApp } from 'firebase/app'

const KEY_AUTH = 'auth'

export const setLocal = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data))
}

export const removeLocal = name => {
  localStorage.removeItem(name)
}

export const getLocal = name => {
  try {
    return JSON.parse(localStorage.getItem(name) || 'null')
  } catch (e) {
    removeLocal(name)
    return null
  }
}

interface AuthState {
  data: any;
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: any;
  isLoading: boolean;
}

const initialState = {
  data: getLocal(KEY_AUTH),
  status: 'idle',
  error: null,
  isLoading: false
} as AuthState

export const handleLogin = createAsyncThunk(
  'auth/login',
  async ({email, password}): any => {
    const app = getApp('BGK')
    const auth = getAuth(app)
    await setPersistence(auth, browserLocalPersistence)
    return signInWithEmailAndPassword(auth, email, password).then(value => value.user?.toJSON())
  }
)

export const handleLogout = createAsyncThunk(
  'auth/logout',
  async (): any => {
    const app = getApp('BGK')
    const auth = getAuth(app)
    return signOut(auth)
  }
)

const authSlice = createSlice<AuthState, any>({
  name: 'auth',
  initialState,
  reducers: null,
  extraReducers: (builder) => {
    builder.addCase(handleLogin.pending, (state) => {
      state.status = 'pending'
      state.isLoading = true
    })
    builder.addCase(handleLogin.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.isLoading = false
      state.data = action.payload
      setLocal(KEY_AUTH, action.payload)
    })
    builder.addCase(handleLogin.rejected, (state, action: any) => {
      state.status = 'failed'
      state.isLoading = false
      state.error = action.error
    })
    builder.addCase(handleLogout.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(handleLogout.fulfilled, (state) => {
      state.status = 'succeeded'
      state.data = null
      removeLocal(KEY_AUTH)
    })
    builder.addCase(handleLogout.rejected, (state, action: any) => {
      state.status = 'failed'
      state.data = null
      state.error = action.error
      removeLocal(KEY_AUTH)
    })
  }
})

export default authSlice.reducer
