import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Tabs } from 'antd'
import { useAppSelector } from 'store'
import _ from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'
import Video from 'components/Video'

const MovieTabs = () => {
  const params = useParams()
  const navigate = useNavigate()

  const filmData = useAppSelector(state => state.film.data)
  const [ source, setSource ] = useState(null)

  const handleChangeEpisode = useCallback((item) => {
    if (item) {
      navigate(`/${ params.slug }/${ item.season }/${ item.episode }`)
    }
  }, [ params, navigate ])

  const sources = useMemo(() => {
    const list = []

    if (filmData?.movie?.playlist?.seasons.length) {
      for (const season of _.sortBy(filmData?.movie?.playlist?.seasons, 'season')) {
        list.push(..._.sortBy(season.episodes, 'episode').map(episode => ({
          sources: [ {
            src: episode.hls,
            type: 'application/x-mpegURL'
          } ],
          poster: episode.poster,
          season: season.season,
          episode: episode.episode,
          title: episode.title
        })))
      }
    }

    return list
  }, [ filmData ])

  useEffect(() => {
    if (filmData.movie?.playlist) {
      const season = _.find(filmData.movie?.playlist?.seasons, {season: _.toNumber(params.season) || 1})
      const episode = _.find(season?.episodes, {episode: params.episode || '1'})

      setSource(episode)
    } else {
      setSource(filmData.movie)
    }
  }, [ filmData, params ])

  return filmData?.trailer?.source?.hls ? (
    <Tabs
      tabPosition={ 'bottom' }
      items={ [ {
        key: 1,
        label: 'Плеер',
        children: <Video
          src={ source?.hls || source?.source?.hls }
          poster={ source?.poster }
          audioNames={ source?.source?.audio || source?.audio }
          sources={ sources }
          handleChangeEpisode={ handleChangeEpisode }
        />
      }, {
        key: 2,
        label: 'Трейлер',
        children: <Video
          src={ filmData?.trailer?.source?.hls }
          poster={ filmData?.trailer?.poster }
          audioNames={ filmData?.trailer?.source?.audio }
        />
      } ] }
    />
  ) : (
    <Video
      src={ source?.hls || source?.source?.hls }
      poster={ source?.poster }
      audioNames={ source?.source?.audio || source?.audio }
      sources={ sources }
      handleChangeEpisode={ handleChangeEpisode }
    />
  )
}

export default MovieTabs
