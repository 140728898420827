import React, { useCallback } from 'react'
import { Button, Card, Col, Form, Input, Row, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from 'store'
import { handleLogin } from 'store/auth'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Layout from 'components/Layout'

const Login = () => {
  const dispatch = useAppDispatch()

  const isLoading = useAppSelector(store => store.auth.isLoading)

  const onSubmit = useCallback(({email, password}) => {
    dispatch(handleLogin({email, password}))
  }, [ dispatch ])

  const {handleChange, errors, handleSubmit} = useFormik({
    onSubmit,
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup.string().required()
    }),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false
  })

  return (
    <Layout>
      <Row justify="center" align="middle" style={ {height: '70vh'} }>
        <Col xs={ 24 } sm={ 24 } md={ 8 } lg={8} xl={6} xxl={4}>
          <Card>
            <Form layout="vertical">
              <Row justify="center" align="middle">
                <Col>
                  <Typography.Title level={ 2 }>Авторизация</Typography.Title>
                </Col>
                <Col span={ 24 }>
                  <Form.Item name="email" validateStatus={ !!errors?.email && 'error' }>
                    <Input name="email" onChange={ handleChange } placeholder="Введите email"/>
                  </Form.Item>
                </Col>
                <Col span={ 24 }>
                  <Form.Item name="password" validateStatus={ !!errors?.password && 'error' }>
                    <Input type="password" name="password" onChange={ handleChange } placeholder="Введите пароль"/>
                  </Form.Item>
                </Col>
                <Col>
                  <Button loading={ isLoading } type="primary" onClick={ () => handleSubmit() }>
                    Войти
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default Login
