import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './Home/route'

const Routes = () => {
  return (
    <RouterProvider router={ createBrowserRouter([ Home ]) }/>
  )
}

export default Routes
