import React from 'react'
import CardMovie from 'components/CardMovie'
import './styles.css'

const CarouselCardMovie = ({entities, showOpen = true}) => {
  return (
    <div className="carousel">
      { entities?.map(item =>
        <div key={ item?.id } className="carousel-item">
          <CardMovie item={ item } showOpen={ showOpen }/>
        </div>
      ) }
    </div>
  )
}

export default CarouselCardMovie
