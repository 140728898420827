import React, { useEffect, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from 'components/Layout'
import { Typography, Card, Row, Col, Breadcrumb } from 'antd'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from 'store'
import { handleFilmFetch, resetFilm } from 'store/film'
import CarouselCardMovie from 'components/CarouselCardMovie'
import MovieTabs from 'components/MovieTabs'
import './styles.css'
import SeriesTabs from 'components/SeriesTabs'
import Image from 'components/Image'
import { HomeOutlined } from '@ant-design/icons'

const filmTypes = [ {
  slug: 'films', name: 'Фильмы'
}, {
  slug: 'cartoons', name: 'Мультфильмы'
}, {
  slug: 'series', name: 'Сериалы'
}, {
  slug: 'tvShows', name: 'Телешоу'
} ]

const Film = () => {
  const params = useParams()
  const dispatch = useAppDispatch()

  const error = useAppSelector(state => state.film.error)
  const filmData = useAppSelector(state => state.film.data)
  const isLoadingFilmData = useAppSelector(state => state.film.isLoading)

  const rate = useMemo(() => {
    const rateList = []

    if (filmData.viewDetails?.rate) {
      if (filmData.viewDetails?.rate.kinopoisk) {
        rateList.push('kinopoisk - ' + filmData.viewDetails?.rate.kinopoisk)
      }

      if (filmData.viewDetails?.rate.imdb) {
        rateList.push('imdb - ' + filmData.viewDetails?.rate.imdb)
      }
    }

    return rateList.join(', ')
  }, [ filmData ])

  const country = useMemo(() => _.map(filmData.viewDetails?.country, 'name').join(', '), [ filmData ])
  const genre = useMemo(() => _.map(filmData.viewDetails?.genre, 'name').join(', '), [ filmData ])

  const breadcrumbItems = useMemo(() => {
    const items = [ {title: <Link to="/"><HomeOutlined/></Link>} ]

    if (filmData.viewDetails) {
      const filmType = filmTypes[filmData.viewDetails?.type - 1]

      if (filmType) {
        items.push({title: <Link to={ `/category/${ filmType.slug }` }>{ filmType.name }</Link>})

        if (filmData.viewDetails?.genre?.length) {
          items.push({
            title: (
              <Link
                to={ `/category/${ filmType.slug }/${ filmData.viewDetails?.genre[0]?.slug }` }
              >
                { filmData.viewDetails?.genre[0]?.name }
              </Link>
            )
          })
        }
      }

      items.push({title: filmData.view?.name})
    }

    return items
  }, [ filmData ])

  useEffect(() => {
    if (!error && params.slug !== filmData?.view?.slug) {
      dispatch(handleFilmFetch(params))
    }
  }, [ params, dispatch, error, filmData?.view?.slug ])

  useEffect(() => {
    return () => {
      dispatch(resetFilm())
    }
  }, [ dispatch ])

  return (
    <Layout loading={ isLoadingFilmData } title={ filmData.view?.name }>
      <Row justify="center" align="middle" gutter={ [ 20, 20 ] }>
        <Col span={ 24 }>
          <Breadcrumb style={ {margin: '0 15px'} } items={ breadcrumbItems }/>
        </Col>
        <Col lg={ 24 } md={ 24 }>
          <Card>
            <Row justify="center" gutter={ [ 20, 20 ] } className="Movie">
              <Col xl={ 12 } lg={ 24 } md={ 24 } sm={ 24 } xs={ 24 }>
                <MovieTabs/>
              </Col>
              <Col xl={ 12 } lg={ 24 } md={ 24 } sm={ 24 } xs={ 24 }>
                <Card>
                  <div className="film-carousel">
                    <div className="film-carousel-item">
                      <Image item={ filmData.view }/>
                    </div>
                    <div className="film-carousel-item">
                      <Typography.Title className="film-title">{ filmData.view?.name }</Typography.Title>
                      { !!filmData.viewDetails?.year && (
                        <Typography.Paragraph>Год: { filmData.viewDetails?.year }</Typography.Paragraph>
                      ) }
                      { rate && (
                        <Typography.Paragraph>Рейтинг: { rate }</Typography.Paragraph>
                      ) }
                      { country && (
                        <Typography.Paragraph>Страна: { country }</Typography.Paragraph>
                      ) }
                      { genre && (
                        <Typography.Paragraph>Жанры: { genre }</Typography.Paragraph>
                      ) }
                      { !!filmData.viewDetails?.description && (
                        <>
                          <Typography.Paragraph>Описание</Typography.Paragraph>
                          <Typography.Paragraph>{ filmData.viewDetails?.description }</Typography.Paragraph>
                        </>
                      ) }
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={ 24 }>
                <SeriesTabs/>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={ 24 } md={ 24 }>
          <Row gutter={ [ 20, 20 ] }>
            <Col>
              <Typography.Title className="title">Рекомендации</Typography.Title>
            </Col>
            <Col span={ 24 }>
              <CarouselCardMovie entities={ filmData?.listLock?.items }/>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}
export default Film
